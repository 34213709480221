/* Global Styles (also change themes in app.js for Mat UI*/

@import url("https://fonts.googleapis.com/css?family=Quicksand|Raleway&display=swap");

:root {
  --primary-color: #277cff;
  --primary-light-color: #92b1ff;
  --primary-dark-color: #5686fb;
  --secondary-color: #ffa41d;
  --secondary-dark-color: #ef8f00;
  --tertiary-color: #b3c7d6;
  --tertiary-dark-color: #a0b4c7;
  --light-color: #c2fbef;
  --danger-color: #ff7a62;
  --danger-hover-color: "#FF3E1A";
  --success-color: #61c9a8;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

p {
  margin: 0px;
  font-size: 12px;
  color: "#fff";
}

.spinner {
  position: absolute;
  left: calc(50% - 60px);
  top: calc(50% - 60px);
  height: 120px;
  width: 120px;
  margin: 0px auto;
  -webkit-animation: spin 0.6s infinite linear;
  -moz-animation: spin 0.6s infinite linear;
  -o-animation: spin 0.6s infinite linear;
  animation: spin 0.6s infinite linear;
  border-left: 6px solid var(--primary-color);
  border-right: 6px solid var(--primary-color);
  border-bottom: 6px solid var(--primary-color);
  border-top: 6px solid var(--secondary-color);
  border-radius: 100%;
  z-index: 1000;
}

/* Safari Spinner */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(119, 119, 119);
  box-shadow: inset 0 0 6px rgb(119, 119, 119);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(185, 185, 185);
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
}

body {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #000;
  background-color: #fff;
  /* overflow: hidden; */
  height: 100%;
  display: block;
  padding: 0px;
}

hr {
  border: 0;
  border-top: 1px solid var(--primary-color);
}
a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  width: 100%;
}

/* Utilities */
.container {
  /* max-width: 1100px; */
  margin: auto;
  overflow: hidden;
  padding: 2rem 4rem;
  margin-top: 3rem;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--secondary-color);
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
  border-radius: 5px;
}

.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary {
  background: var(--primary-color);
  color: #333;
}

.btn-light,
.bg-light,
.badge-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark {
  background: var(--primary-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

.line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
}

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

.text-danger {
  color: var(--danger-color);
}
/* Mobile Styles */
@media (max-width: 700px) {
  .container {
    padding:1rem;
    margin-top: 3rem;
  }

  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: none;
    display: block;
    text-align: center;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 1rem;
  }

  .navbar .welcome {
    display: none;
  }
}

/* editor css */
#gjs {
  /* border: 3px solid #444; */
  height: calc(100vh - 40px) !important;
  overflow: hidden;
  width: 100%;
  display: block;
}
/*Primary BG color of editor*/
.gjs-one-bg {
  background-color: var(--primary-color);
}

/* Secondary color for the text color */
.gjs-two-color {
  color: rgba(255, 255, 255, 0.7);
}

/* Tertiary color for the background */
.gjs-three-bg {
  background-color: var(--tertiary-color);
  color: white;
}

/* Quaternary color for the text color */
.gjs-four-color,
.gjs-four-color-h:hover {
  color: var(--primary-light-color);
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}

.gjs-block {
  min-height: auto;
}

.panel__top {
  padding: 0;
  height: 40px;
  width: 100%;
  display: flex;
  position: initial;
}

.panel__view-actions {
  position: initial;
  margin-left: auto;
}

.panel__more-actions {
  position: initial;
  margin-left: 0;
}

.editor-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
}
.bottom-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
}
.editor-canvas {
  flex-grow: 1;
}

.panel__right {
  flex-basis: 230px;
  position: relative;
  border-left: solid rgba(0, 0, 0, 0.1);
  padding: 0;
  height: 100vh !important;
}

.panel__switcher {
  border-bottom: solid rgba(0, 0, 0, 0.1);
  position: initial;
  justify-content: left;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  display: block;
}

.panel__elements {
  padding: 0;
  height: calc(100vh - 40px) !important;
  overflow-y: auto;
}

.gjs-pn-btn {
  width: 100%;
}

.gjs-cv-canvas .gjs-highlighter {
  outline: 1px solid var(--secondary-color);
}

.gjs-toolbar {
  background-color: var(--secondary-color);
}
.gjs-resizer-h {
  border: 3px solid var(--secondary-color);
}

.gjs-badge {
  background-color: var(--secondary-color);
}

#wrapper {
  padding: 0px;
}

.gjs-button {
  border-radius: 5px;
  width: 100%;
  font: inherit;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 6px 16px;
  font-size: 15px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Raleway", sans-serif;
  line-height: 1.75;
  color: white;
  border: 0px;
  font-weight: normal;
  text-transform: none;
  background-color: var(--primary-color);
  margin: 0px;
  height: 100%;
  box-shadow: none;
}

.gjs-button:hover {
  border: "0px";
  color: var(--secondary-color);
  background-color: var(--primary-color);
}

/*for the progress bar*/
.indexedStep {
  color: #fff;
  width: 35px;
  height: 35px;
  font-size: 16px;
  background-color: rgb(179, 179, 179);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indexedStep.accomplished {
  background-color: var(--secondary-color);
}
